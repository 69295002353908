<template>
  <BCard title="입력 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="ADV_IDX">
          <BFormInput
            :value="advIdx"
            @input="$emit('input-adv-idx', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="SNS_ID">
          <BFormInput
            :value="username"
            @input="$emit('input-username', $event)"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    advIdx: {
      type: Number,
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
  },
}
</script>
