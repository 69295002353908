import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import uploadFile from '@/hooks/uploadFile'
import storeModule from './storeModule'

export default function () {
  // 모든 기능에서 공통적으로 사용
  const APP_STORE_MODULE_NAME = 'app-bca-create'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })

  const advList = ref([])
  const fetchAdv = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/create`)
      .then(response => {
        advList.value = response.data.data
      })
  }
  const advName = ref()
  const inputAdvName = value => {
    advName.value = value
  }
  const advIdx = ref()
  const inputAdvIdx = value => {
    advIdx.value = value
  }
  const username = ref()
  const inputUsername = value => {
    username.value = value
  }

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(advIdx.value) && Boolean(username.value))

  const create = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/create`,
      {
        adv_idx: advIdx.value,
        username: username.value,
      })
      .then(response => {
        router.push({ name: 'brand-sns-detail', params: { idx: response.data.data.idx } })
        makeToast('primary', '생성에 성공 했습니다')
      }).catch(() => {
        makeToast('danger', '생성에 실패 했습니다')
      }).finally(() => {
        isSaving.value = false
      })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    advIdx,
    inputAdvIdx,
    username,
    inputUsername,

    isSaving,
    isValid,
    create,
  }
}
