<template>
  <div>
    <BRow>
      <BCol
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <InputInfoCard
          :advIdx="advIdx"
          :username="username"
          @input-adv-idx="inputAdvIdx"
          @input-username="inputUsername"
        />
      </BCol>
      <BCol
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <ActionCard
          :isSaving="isSaving"
          :isValid="isValid"
          @save="create"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import InputInfoCard from './components/InputInfoCard.vue'
import ActionCard from './components/ActionCard.vue'

export default {
  components: {
    BRow,
    BCol,
    InputInfoCard,
    ActionCard,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
